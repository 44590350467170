import React, { useContext, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from '@mui/material'
import EditDialog from '../EditDialog/EditDialog'
import EditIcon from '@mui/icons-material/Edit'
import GroupsContext from '../../app/Administration/GroupsContext'
import api from '../../shared/api'
import { useAuth } from '../../app/OAuth/Provider'

function OptionsGroupsTable() {
  const { getToken } = useAuth()

  const { data, setData } = useContext(GroupsContext)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [currentRow, setCurrentRow] = useState(null)

  const handleEditClick = (row) => {
    setCurrentRow(row)
    setDialogOpen(true)
  }

  const handleSave = (updatedRow) => {
    const updatedData = data.map((row) =>
      row.id === updatedRow.id ? updatedRow : row
    )
    setData(updatedData)
    getToken().then((accessToken) => {
      api
        .put(`/v1/admins/groups/update/${updatedRow.id}`, updatedRow, {
          authorization: accessToken,
        })
        .then(() => {
          console.log('Данные успешно изменены')
          setDialogOpen(false)
        })
        .catch((error) => {
          console.error('Ошибка изменения данных:', error)
        })
    })
  }

  return (
    <>
      {currentRow && (
        <EditDialog
          show={dialogOpen}
          onHide={() => setDialogOpen(false)}
          onSave={handleSave}
          title="Редактирование данных"
          fields={[{ key: 'name', label: 'Название' }]}
          data={currentRow}
          setData={setCurrentRow}
        />
      )}
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Код</TableCell>
              <TableCell>Название</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={5}
                  sx={{
                    textAlign: 'center',
                    color: '#999',
                    height: 70,
                  }}
                >
                  Список пуст
                </TableCell>
              </TableRow>
            ) : null}

            {data.map((row) => (
              <TableRow
                key={row.id}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell sx={{ width: '15%' }}>{row.id}</TableCell>
                <TableCell sx={{ width: '15%' }}>{row.name}</TableCell>
                <TableCell align="right">
                  <IconButton
                    aria-label="edit"
                    onClick={() => handleEditClick(row)}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default OptionsGroupsTable
