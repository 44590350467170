import React, { useContext, useState } from 'react'
import {
  Alert,
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import PropTypes from 'prop-types'
import JournalsContext from '../../app/Sonars/JournalsContext'
import FormattedDate from './FormattedDate'
import { useAuth } from '../../app/OAuth/Provider'
import api from '../../shared/api'
import DeleteIcon from '@mui/icons-material/Delete'
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog'
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded'

function JournalsTable() {
  const { getToken } = useAuth()

  const [deleted, setDeleted] = useState({})
  const [deletingId, setDeletingId] = useState(null)
  const [showConfirm, setShowConfirm] = useState(false)

  const handleDownload = (row) =>
    getToken().then((accessToken) => {
      api
        .get(row.url, {
          authorization: accessToken,
        })
        .then((res) => {
          const link = document.createElement('a')
          link.href = URL.createObjectURL(res.blob)
          link.setAttribute('download', res.filename)
          document.body.appendChild(link)
          link.click()
          link.parentNode.removeChild(link)
        })
    })

  const handleConfirmRemove = (row) => {
    console.log(row)
    setDeletingId(row.id)
    setShowConfirm(true)
  }

  const handleRemove = () =>
    getToken().then((accessToken) => {
      api
        .delete('/v1/sonars/journal/remove/' + deletingId, {
          authorization: accessToken,
        })
        .then((_) => {
          const newDeleted = {}
          for (const d in deleted) {
            newDeleted[d] = true
          }
          newDeleted[deletingId] = true
          setDeleted(newDeleted)
        })
        .catch((e) => {
          console.log(e)
        })
    })

  const renderTable = (rows) => {
    return (
      <>
        <ConfirmDialog
          show={showConfirm}
          message={'Удалить журнал?'}
          onHide={() => setShowConfirm(false)}
          accept={handleRemove}
        />
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Дата</TableCell>
                <TableCell>Серийный номер</TableCell>
                <TableCell>Хеш журнала (sha1)</TableCell>
                <TableCell align={'right'}>Размер журнала</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={5}
                    sx={{
                      textAlign: 'center',
                      color: '#999',
                      height: 70,
                    }}
                  >
                    Список пуст
                  </TableCell>
                </TableRow>
              ) : null}
              {rows.map((row) =>
                deleted[row.id] ? (
                  <TableRow
                    key={row.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell
                      colSpan={5}
                      sx={{
                        textAlign: 'center',
                        color: '#999',
                        height: 70,
                        background:
                          'linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0.03) 50%, rgba(0,0,0,0))',
                      }}
                    >
                      Журнал удален
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>
                      <FormattedDate timestamp={row.creationDate} />
                    </TableCell>
                    <TableCell>{row.serial}</TableCell>
                    <TableCell>
                      <Chip label={row.hash} size={'small'} sx={{ mt: 1 }} />
                    </TableCell>
                    <TableCell align={'right'}>
                      <Box sx={{ p: 1 }}>
                        {Math.round((row.size / 1024) * 100) / 10} Кб
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Stack direction={'row'} spacing={1}>
                        <IconButton
                          aria-label="download"
                          onClick={() => handleDownload(row)}
                        >
                          <GetAppRoundedIcon />
                        </IconButton>
                        <Box width={10} />
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleConfirmRemove(row)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Stack>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }

  const { data, loading, error } = useContext(JournalsContext)
  return (
    <Box sx={{ my: 2 }}>
      {loading ? <CircularProgress /> : <Box>{renderTable(data)}</Box>}
      {error != null && (
        <Alert variant="outlined" severity="error">
          {error}
        </Alert>
      )}
    </Box>
  )
}

JournalsTable.propTypes = {
  view: PropTypes.string, // chart, diagram, table
  type: PropTypes.string,
  children: PropTypes.node,
}

export default JournalsTable
