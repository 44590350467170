import React, { useEffect, useState } from 'react'
import { useAuth } from '../../app/OAuth/Provider'
import { Box } from '@mui/material'
import SystemMenu from '../../shared/ui/SystemMenu'
import ContentPaper from '../../shared/ui/Components/ContentPaper'
import SelectOptionsIndicators from '../../widgets/Select/SelectOptionsIndicators'
import OptionsGroupTable from '../../widgets/DataView/OptionsGroupsTable'
import OptionsTypesTable from '../../widgets/DataView/OptionsTypesTable'
import OptionsTokenAccessTable from '../../widgets/DataView/OptionsTokenAccessTable'
import TokenAccessProvider from '../../app/Administration/TokenAccessProvider'
import GroupsProvider from '../../app/Administration/GroupsProvider'
import TypesProvider from '../../app/Administration/TypesProvider'
import ToggleGroup from '../../shared/ui/Components/ToggleGroup'

function Options() {
  const { isAuthenticated, getToken, login } = useAuth()
  const [view, setView] = useState('groups')

  useEffect(() => {
    getToken()
      .then((r) => {})
      .catch((r) => login())
  }, [])

  useEffect(() => {
    if (!isAuthenticated) login()
  }, [isAuthenticated])

  const [selectedGroup, setSelectedGroup] = useState('any')

  const handleGroupChange = (event) => {
    setSelectedGroup(event.target.value)
  }

  return (
    <SystemMenu>
      <h1>Настройки</h1>

      <ToggleGroup
        onChanged={setView}
        buttons={[
          {
            key: 'groups',
            name: 'Группы',
          },
          {
            key: 'indicators',
            name: 'Показатели',
          },
          {
            key: 'tokens',
            name: 'Токены доступа',
          },
        ]}
      />

      {view === 'groups' && (
        <ContentPaper>
          <GroupsProvider>
            <OptionsGroupTable />
          </GroupsProvider>
        </ContentPaper>
      )}
      {view === 'indicators' && (
        <>
          <TypesProvider>
            <SelectOptionsIndicators
              selectedGroup={selectedGroup}
              handleGroupChange={handleGroupChange}
            />
          </TypesProvider>

          <ContentPaper>
            <TypesProvider>
              <OptionsTypesTable
                selectedGroup={selectedGroup}
                handleGroupChange={handleGroupChange}
              />
            </TypesProvider>
          </ContentPaper>
        </>
      )}
      {view === 'tokens' && (
        <TokenAccessProvider query={{}}>
          <OptionsTokenAccessTable />
        </TokenAccessProvider>
      )}
      <Box height={20} />
    </SystemMenu>
  )
}

export default Options
