import React, { useState, useContext } from 'react'
import api from '../../shared/api'
import PropTypes from 'prop-types'
import TypesContext from '../../app/Administration/TypesContext'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TablePagination,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import EditDialog from '../EditDialog/EditDialog'
import { useAuth } from '../../app/OAuth/Provider'

function OptionsTypesTable({ selectedGroup }) {
  const { getToken } = useAuth()

  const { data, setData } = useContext(TypesContext)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [currentRow, setCurrentRow] = useState(null)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const filteredData = data.filter(
    (row) =>
      selectedGroup === 'any' || !selectedGroup || row.group === selectedGroup
  )

  const handleEditClick = (row) => {
    setCurrentRow(row)
    setDialogOpen(true)
  }

  const handleSave = (updatedRow) => {
    const updatedData = data.map((row) =>
      row.id === updatedRow.id ? updatedRow : row
    )
    setData(updatedData)
    getToken().then((accessToken) => {
      api
        .put(`/v1/admins/types/update/${updatedRow.id}`, updatedRow, {
          authorization: accessToken,
        })
        .then(() => {
          console.log('Данные успешно изменены')
          setDialogOpen(false)
        })
        .catch((error) => {
          console.error('Ошибка изменения данных:', error)
        })
    })
  }

  return (
    <>
      {currentRow && (
        <EditDialog
          show={dialogOpen}
          onHide={() => setDialogOpen(false)}
          onSave={handleSave}
          title="Редактирование данных"
          fields={[
            { key: 'name', label: 'Название' },
            { key: 'description', label: 'Описание' },
          ]}
          data={currentRow}
          setData={setCurrentRow}
        />
      )}
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Группа</TableCell>
              <TableCell>Код</TableCell>
              <TableCell>Название</TableCell>
              <TableCell>Описание</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={5}
                  sx={{
                    textAlign: 'center',
                    color: '#999',
                    height: 70,
                  }}
                >
                  Список пуст
                </TableCell>
              </TableRow>
            )}

            {filteredData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow
                  key={row.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell sx={{ color: '#8f8f8f' }}>{row.group}</TableCell>
                  <TableCell sx={{ color: '#8f8f8f' }}>{row.code}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.description}</TableCell>
                  <TableCell align={'right'}>
                    <IconButton
                      aria-label="edit"
                      onClick={() => handleEditClick(row)}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {data.length !== 0 && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Строк на страницу:"
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} из ${count !== -1 ? count : `более чем ${to}`}`
          }
          sx={{
            '& .MuiTablePagination-selectLabel': {
              marginBottom: '0rem !important',
            },
            '& .MuiTablePagination-displayedRows': {
              marginBottom: '0rem !important',
            },
          }}
        />
      )}
    </>
  )
}

OptionsTypesTable.propTypes = {
  selectedGroup: PropTypes.string,
}

export default OptionsTypesTable
