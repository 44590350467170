import React, { useEffect } from 'react'
import { useAuth } from '../../app/OAuth/Provider'
import { Box, Stack } from '@mui/material'
import Chart from '../../widgets/Chart/Chart'
import StatsProvider from '../../app/Stats/StatsProvider'
import InstallationsTable from '../../widgets/DataView/InstallationsTable'
import SystemMenu from '../../shared/ui/SystemMenu'
import ContentPaper from '../../shared/ui/Components/ContentPaper'

const getStartDate = (monthBefore) => {
  if (monthBefore === undefined) monthBefore = 1
  const d = new Date()
  d.setMonth(d.getMonth() - monthBefore)
  d.setHours(0, 0, 0, 0)
  return d.getTime() / 1000
}

function Installations() {
  const { isAuthenticated, getToken, login } = useAuth()

  useEffect(() => {
    getToken()
      .then((r) => {})
      .catch((r) => login())
  }, [])

  useEffect(() => {
    if (!isAuthenticated) login()
  }, [isAuthenticated])

  return (
    <SystemMenu>
      <h1>Установки Мой Практик</h1>
      <Stack direction={'column'}>
        <StatsProvider
          type="installations"
          query={{
            segment: true,
            segmentField: 'creationDate',
            segmentSize: 2,
            startDate: getStartDate(),
          }}
        >
          <ContentPaper>
            <h2 style={{ textAlign: 'left' }}>Количество установок по дням</h2>
            <Chart segmentSize={24 * 3600 * 1000} />
          </ContentPaper>
        </StatsProvider>
        <StatsProvider type="installations" query={{}}>
          <ContentPaper>
            <h2 style={{ textAlign: 'left' }}>Последние установки</h2>
            <InstallationsTable />
          </ContentPaper>
        </StatsProvider>
        <Box height={20} />
      </Stack>
    </SystemMenu>
  )
}

export default Installations
