import React, { useState, useContext } from 'react'
import {
  Box,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Paper,
  FormHelperText,
  IconButton,
} from '@mui/material'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
} from 'recharts'
import IndicatorsContext from '../../app/Stats/IndicatorsContext'
import BarChartIcon from '@mui/icons-material/BarChartRounded'
import TimelineIcon from '@mui/icons-material/TimelineRounded'

const ChartsIndicators = () => {
  const { data } = useContext(IndicatorsContext)

  const [state, setState] = useState({
    period: 'Квартал',
    detail: 'Месяц',
    chartType: 'bar',
  })

  const handleStateChange = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }))
  }

  const getLastWeekDates = () => {
    const today = new Date()
    const last7Days = new Date(today)
    last7Days.setDate(today.getDate() - 6)
    return { start: last7Days, end: today }
  }

  const getWeekRange = (date) => {
    const current = new Date(date)
    const first = current.getDate() - current.getDay() + 1
    const last = first + 6
    const startOfWeek = new Date(current.setDate(first))
    const endOfWeek = new Date(current.setDate(last))
    return { start: startOfWeek, end: endOfWeek }
  }

  const formatDate = (date) =>
    `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}`

  const getPeriodName = (date) => {
    const itemDate = new Date(date)
    switch (state.detail) {
      case 'Месяц':
        return itemDate.toLocaleString('default', { month: 'short' })
      case 'Неделя': {
        const { start, end } =
          state.period === 'Неделя'
            ? getLastWeekDates()
            : getWeekRange(itemDate)
        return `${formatDate(start)}-${formatDate(end)}`
      }
      case 'День':
        return itemDate.toLocaleDateString()
      default:
        return itemDate.toLocaleString('default', { month: 'short' })
    }
  }

  const getData = () => {
    const now = new Date()
    let filteredData = []

    const periodFilter = {
      Год: (item) =>
        new Date(item.startDate).getFullYear() === now.getFullYear(),
      Квартал: (item) => {
        const quarterStart = new Date(
          now.getFullYear(),
          Math.floor(now.getMonth() / 3) * 3,
          1
        )
        return (
          new Date(item.startDate) >= quarterStart &&
          new Date(item.startDate) <= now
        )
      },
      Месяц: (item) => {
        const monthStart = new Date(now.getFullYear(), now.getMonth(), 1)
        return (
          new Date(item.startDate) >= monthStart &&
          new Date(item.startDate) <= now
        )
      },
      Неделя: (item) => {
        const { start, end } = getLastWeekDates()
        return (
          new Date(item.startDate) >= start && new Date(item.startDate) <= end
        )
      },
    }

    filteredData = periodFilter[state.period]
      ? data.filter(periodFilter[state.period])
      : data

    if (!filteredData.length) return []

    const groupedData = filteredData.reduce((acc, item) => {
      const periodName = getPeriodName(item.startDate)
      if (!acc[periodName]) {
        acc[periodName] = {
          name: periodName,
          lessThan1K: 0,
          between1And100K: 0,
          moreThan100K: 0,
        }
      }
      acc[periodName].lessThan1K += item.value < 1000 ? 1 : 0
      acc[periodName].between1And100K +=
        item.value >= 1000 && item.value <= 100000 ? 1 : 0
      acc[periodName].moreThan100K += item.value > 100000 ? 1 : 0
      return acc
    }, {})

    return Object.values(groupedData)
  }

  const handlePeriodChange = (event, newPeriod) => {
    if (newPeriod !== null) {
      handleStateChange('period', newPeriod)
      switch (newPeriod) {
        case 'Год':
          handleStateChange(
            'detail',
            state.detail === 'День' ? 'Неделя' : state.detail
          )
          break
        case 'Квартал':
          handleStateChange(
            'detail',
            ['Месяц', 'Неделя', 'День'].includes(state.detail)
              ? state.detail
              : 'Неделя'
          )
          break
        case 'Месяц':
          handleStateChange(
            'detail',
            state.detail === 'Месяц' ? 'Неделя' : state.detail
          )
          break
        case 'Неделя':
          handleStateChange('detail', 'День')
          break
        default:
          break
      }
    }
  }

  const handleDetailChange = (event, newDetail) => {
    if (
      newDetail !== null &&
      !(
        (state.period === 'Год' && newDetail === 'День') ||
        (state.period === 'Месяц' && newDetail === 'Месяц') ||
        (state.period === 'Неделя' && newDetail === 'Месяц') ||
        (state.period === 'Неделя' && newDetail === 'Неделя')
      )
    ) {
      handleStateChange('detail', newDetail)
    }
  }

  const handleChartTypeChange = (event, newChartType) => {
    if (newChartType !== null) {
      handleStateChange('chartType', newChartType)
    }
  }

  const periodOptions = [
    { value: 'Период', text: 'Период' },
    { value: 'Год', text: 'Год' },
    { value: 'Квартал', text: 'Квартал' },
    { value: 'Месяц', text: 'Месяц' },
    { value: 'Неделя', text: 'Неделя' },
  ]

  const detailOptions = [
    { value: 'Месяц', text: 'Месяц' },
    { value: 'Неделя', text: 'Неделя' },
    { value: 'День', text: 'День' },
  ]

  const buttonStyles = {
    textTransform: 'none',
    borderRadius: '1rem',
    height: '42px',
    '&.Mui-selected': {
      backgroundColor: '#FFCC00',
      color: '#444',
    },
  }

  const selectedRow = data.find((row) => row.name_group === 'Глобальные')

  return (
    <Box my={4}>
      <Paper sx={{ borderRadius: '1rem' }}>
        <Box padding={2}>
          {selectedRow && (
            <Typography variant="h6">
              {selectedRow.name_group}
              <FormHelperText>{selectedRow.code}</FormHelperText>
            </Typography>
          )}
          <Box my={2} display="flex">
            <ToggleButtonGroup
              value={state.period}
              exclusive
              onChange={handlePeriodChange}
              aria-label="Выбор периода"
            >
              {periodOptions.map(({ value, text }, index) => (
                <ToggleButton
                  key={index}
                  value={value}
                  sx={buttonStyles}
                  disabled={index === 0}
                >
                  {text}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>

            <ToggleButtonGroup
              value={state.detail}
              exclusive
              onChange={handleDetailChange}
              aria-label="Выбор детализации"
              sx={{ marginLeft: 2 }}
            >
              <ToggleButton disabled sx={buttonStyles}>
                Детализация
              </ToggleButton>
              {detailOptions.map(({ value, text }, index) => (
                <ToggleButton
                  key={index}
                  value={value}
                  sx={buttonStyles}
                  disabled={
                    (state.period === 'Год' && value === 'День') ||
                    (state.period === 'Месяц' && value === 'Месяц') ||
                    (state.period === 'Неделя' && value === 'Месяц') ||
                    (state.period === 'Неделя' && value === 'Неделя')
                  }
                >
                  {text}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>

            <ToggleButtonGroup
              value={state.chartType}
              sx={{ marginLeft: 'auto' }}
              exclusive
              onChange={handleChartTypeChange}
              aria-label="Выбор типа графика"
            >
              <ToggleButton value="bar" sx={buttonStyles}>
                <IconButton>
                  <BarChartIcon />
                </IconButton>
              </ToggleButton>
              <ToggleButton value="line" sx={buttonStyles}>
                <IconButton>
                  <TimelineIcon />
                </IconButton>
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          <ResponsiveContainer width="100%" height={400}>
            {state.chartType === 'bar' ? (
              <BarChart data={getData()}>
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend
                  layout="vertical"
                  verticalAlign="middle"
                  align="right"
                />
                <Bar dataKey="lessThan1K" fill="#a9d300" name="Менее 1К" />
                <Bar dataKey="between1And100K" fill="#64dd17" name="1 - 100К" />
                <Bar dataKey="moreThan100K" fill="#aa4400" name="Более 100К" />
              </BarChart>
            ) : (
              <LineChart data={getData()}>
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend
                  layout="vertical"
                  verticalAlign="middle"
                  align="right"
                />
                <Line
                  type="monotone"
                  dataKey="lessThan1K"
                  stroke="#64dd17"
                  name="Менее 1К"
                />
                <Line
                  type="monotone"
                  dataKey="between1And100K"
                  stroke="#64dd17"
                  name="1 - 100К"
                />
                <Line
                  type="monotone"
                  dataKey="moreThan100K"
                  stroke="#aa4400"
                  name="Более 100К"
                />
              </LineChart>
            )}
          </ResponsiveContainer>
        </Box>
      </Paper>
    </Box>
  )
}

export default ChartsIndicators
