import React, { useState, useContext } from 'react'
import BuildsContext from '../../app/Settings/Builds/BuildsContext'
import PropTypes from 'prop-types'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  Box,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import DoneOutlineOutlinedIcon from '@mui/icons-material/DoneOutlineOutlined'
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded'
import HideSourceIcon from '@mui/icons-material/HideSource'
import api from '../../shared/api'
import EditDialogBuilds from '../EditDialog/EditDialogBuilds'
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog'
import { useAuth } from '../../app/OAuth/Provider'
import { useFeatures } from '../../app/FeatureToggle'

const BuildsTable = ({ selectedBranch, selectedApp }) => {
  const { getToken } = useAuth()

  const { data, setData } = useContext(BuildsContext)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [currentRow, setCurrentRow] = useState(null)

  const [expandedRows, setExpandedRows] = useState({})
  const [deleted, setDeleted] = useState({})
  const [deletingId, setDeletingId] = useState(null)
  const [showConfirm, setShowConfirm] = useState(false)

  const handleDownload = (row) => {
    getToken().then((accessToken) => {
      api
        .get(`/v1/settings/builds/download/${row.id}`, {
          authorization: accessToken,
        })
        .then((res) => {
          const link = document.createElement('a')
          link.href = URL.createObjectURL(res.blob)
          link.setAttribute('download', res.filename)
          document.body.appendChild(link)
          link.click()
          link.parentNode.removeChild(link)
        })
    })
  }

  const handleConfirmRemove = (row) => {
    setDeletingId(row.id)
    setShowConfirm(true)
  }

  const handleRemove = () => {
    getToken().then((accessToken) => {
      api
        .delete(`/v1/settings/builds/remove/${deletingId}`, {
          authorization: accessToken,
        })
        .then((_) => {
          const newDeleted = {}
          for (const d in deleted) {
            newDeleted[d] = true
          }
          newDeleted[deletingId] = true
          setDeleted(newDeleted)
        })
        .catch((e) => {
          console.log(e)
        })
    })
  }

  const handleEditClick = (row) => {
    setCurrentRow(row)
    setDialogOpen(true)
  }

  const handleSave = (updatedRow) => {
    const updatedData = data.map((row) =>
      row.id === updatedRow.id ? updatedRow : row
    )
    setData(updatedData)

    getToken().then((accessToken) => {
      api
        .put(`/v1/settings/builds/update/${updatedRow.id}`, updatedRow, {
          authorization: accessToken,
        })
        .then(() => {
          console.log('Данные успешно изменены')
          setDialogOpen(false)
        })
        .catch((error) => {
          console.error('Ошибка изменения данных:', error)
        })
    })
  }

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text
    return `${text.slice(0, maxLength)}...`
  }

  const handleTextClick = (id) => {
    setExpandedRows((prev) => ({ ...prev, [id]: !prev[id] }))
  }

  const colorThread = (text) => {
    const parts = text.split(/(test|release|dev)/)
    return parts.map((part, index) =>
      part === 'test' ? (
        <Box
          component="span"
          key={index}
          sx={{
            background: '#ffcc00',
            borderRadius: '10px',
            padding: '3px 8px',
            margin: '0 3px',
          }}
        >
          {part}
        </Box>
      ) : part === 'release' ? (
        <Box
          component="span"
          key={index}
          sx={{
            background: '#aad400',
            borderRadius: '10px',
            padding: '3px 8px',
            margin: '0 3px',
          }}
        >
          {part}
        </Box>
      ) : part === 'dev' ? (
        <Box
          component="span"
          key={index}
          sx={{
            background: '#c90b0b',
            borderRadius: '10px',
            color: '#fff',
            padding: '3px 8px',
            margin: '0 3px',
          }}
        >
          {part}
        </Box>
      ) : (
        part
      )
    )
  }

  const filteredData = data?.filter((row) => {
    const branchMatches =
      selectedBranch === 'any' ||
      !selectedBranch ||
      row.thread === selectedBranch
    const appMatches =
      selectedApp === 'any' || !selectedApp || row.app === selectedApp
    return branchMatches && appMatches
  })

  const features = useFeatures()

  return (
    <>
      <ConfirmDialog
        show={showConfirm}
        message={'Удалить сборку?'}
        onHide={() => setShowConfirm(false)}
        accept={handleRemove}
      />
      {currentRow && (
        <EditDialogBuilds
          show={dialogOpen}
          onHide={() => setDialogOpen(false)}
          onSave={handleSave}
          data={currentRow}
          setData={setCurrentRow}
        />
      )}
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="builds table">
          <TableHead>
            <TableRow>
              {features.includes('BUILD_PUBLISH') ? (
                <TableCell sx={{ width: '6%' }}>Опубликовано</TableCell>
              ) : null}
              <TableCell>Дата</TableCell>
              <TableCell>Версия</TableCell>
              <TableCell>Размер</TableCell>
              <TableCell>Изменения</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={5}
                  sx={{
                    textAlign: 'center',
                    color: '#999',
                    height: 70,
                  }}
                >
                  Список пуст
                </TableCell>
              </TableRow>
            ) : null}
            {filteredData.map((row) =>
              deleted[row.id] ? (
                <TableRow
                  key={row.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell
                    colSpan={5}
                    sx={{
                      textAlign: 'center',
                      color: '#999',
                      height: 70,
                      background:
                        'linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0.03) 50%, rgba(0,0,0,0))',
                    }}
                  >
                    Сборка удалена
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  {features.includes('BUILD_PUBLISH') ? (
                    <TableCell>
                      {row.publish ? (
                        <IconButton aria-label="done">
                          <DoneOutlineOutlinedIcon />
                        </IconButton>
                      ) : (
                        <IconButton aria-label="hide" sx={{ color: '#b71c1c' }}>
                          <HideSourceIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  ) : null}
                  <TableCell sx={{ color: '#555' }}>
                    {row.creationDate}
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        columnGap: '3px',
                      }}
                    >
                      {row.version}
                      {colorThread(row.thread)}
                      {row.build}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        columnGap: '7px',
                      }}
                    >
                      {row.filesize}
                      <IconButton
                        aria-label="download"
                        onClick={() => handleDownload(row)}
                      >
                        <GetAppRoundedIcon />
                      </IconButton>
                    </div>
                  </TableCell>
                  <TableCell>
                    <Typography
                      onClick={() => handleTextClick(row.id)}
                      sx={{
                        cursor: 'pointer',
                      }}
                    >
                      {expandedRows[row.id]
                        ? row.changes
                        : truncateText(row.changes, 65)}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <IconButton
                      aria-label="edit"
                      disabled={true}
                      onClick={() => handleEditClick(row)}
                    >
                      <EditIcon />
                    </IconButton>
                    {row.thread !== 'release' && (
                      <IconButton
                        aria-label="delete"
                        sx={{ color: '#b71c1c' }}
                        onClick={() => handleConfirmRemove(row)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

BuildsTable.propTypes = {
  selectedBranch: PropTypes.string,
  selectedApp: PropTypes.string,
}

export default BuildsTable
