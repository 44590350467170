import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import StatsContext from './StatsContext'
import api from '../../shared/api'
import { useAuth } from '../OAuth/Provider'

function StatsProvider({ children, query, type }) {
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [data, setData] = useState([])

  const { getToken } = useAuth()

  useEffect(() => {
    getToken().then((accessToken) =>
      api
        .get('/v1/stats/' + type + '?' + api.objectToQuery(query), {
          authorization: accessToken,
        })
        .then((r) => {
          setData(r)
          setLoading(false)
          setLoaded(true)
        })
        .catch((e) => {
          setError(e.message)
        })
    )
  }, [])

  const contextValue = useMemo(
    () => ({
      loaded,
      loading,
      error,
      data,
    }),
    [loaded, loading, error, data]
  )

  return (
    <StatsContext.Provider value={contextValue}>
      {children}
    </StatsContext.Provider>
  )
}

StatsProvider.propTypes = {
  children: PropTypes.object,
  type: PropTypes.string,
  query: PropTypes.shape({
    startDate: PropTypes.number,
    endDate: PropTypes.number,
  }),
}

export default StatsProvider
