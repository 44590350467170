import React, { useState } from 'react'
import {
  Box,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  toggleButtonGroupClasses,
} from '@mui/material'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: '0.5rem',
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
    {
      marginLeft: -1,
      borderLeft: '1px solid transparent',
    },
}))

function ToggleGroup({ buttons, onChanged }) {
  const checkSelected = (v) => {
    for (const r of buttons) {
      if (r.key === v) return v
    }
    return buttons.length > 0 ? buttons[0].key : ''
  }

  const [selected, setSelected] = useState(checkSelected(''))

  const handleSelect = (e, v) => {
    const cv = checkSelected(v)
    setSelected(cv)
    onChanged(cv)
  }

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          border: `none`,
          borderRadius: '0.8rem',
          flexWrap: 'wrap',
        }}
      >
        <StyledToggleButtonGroup
          color="secondary"
          value={selected}
          exclusive
          onChange={handleSelect}
        >
          {buttons.map((row, index) => (
            <ToggleButton value={row.key} key={index}>
              {row.name}
            </ToggleButton>
          ))}
        </StyledToggleButtonGroup>
      </Paper>
      <Box height={20} />
    </>
  )
}

ToggleGroup.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChanged: PropTypes.func,
}

export default ToggleGroup
