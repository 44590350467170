import React, { useContext } from 'react'
import { Stack, Select, MenuItem, FormControl } from '@mui/material'
import TypesContext from '../../app/Administration/TypesContext'
import PropTypes from 'prop-types'

function SelectOptionsIndicators({ selectedGroup, handleGroupChange }) {
  const { data } = useContext(TypesContext)
  const uniqueGroups = [...new Set(data.map((row) => row.group))]
  return (
    <>
      <Stack sx={{ mt: 2 }}>
        <FormControl
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h3 style={{ marginRight: '1rem' }}>Группа</h3>
          <Select value={selectedGroup} onChange={handleGroupChange}>
            <MenuItem value="any">Любая</MenuItem>
            {uniqueGroups.map((group, index) => (
              <MenuItem key={index} value={group}>
                {group}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </>
  )
}

SelectOptionsIndicators.propTypes = {
  selectedGroup: PropTypes.string,
  handleGroupChange: PropTypes.func,
}

export default SelectOptionsIndicators
