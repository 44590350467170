import React, { useContext, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Box,
} from '@mui/material'
import EditDialog from '../EditDialog/EditDialog'
import EditIcon from '@mui/icons-material/Edit'
import CreateDialog from '../CreateDialog/CreateDialogApps'
import AppsContext from '../../app/Sonars/AppsContext'
import api from '../../shared/api'
import { useAuth } from '../../app/OAuth/Provider'
import ContentPaper from '../../shared/ui/Components/ContentPaper'
function AppsTable() {
  const { getToken } = useAuth()

  const { dataApps, setData, refreshApps } = useContext(AppsContext)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [createDialogOpen, setCreateDialogOpen] = useState(false)
  const [currentRow, setCurrentRow] = useState(null)

  const handleEditClick = (row) => {
    setCurrentRow(row)
    setDialogOpen(true)
  }

  const handleSave = (updatedRow) => {
    const updatedData = dataApps.map((row) =>
      row.id === updatedRow.id ? updatedRow : row
    )
    setData(updatedData)

    getToken().then((accessToken) => {
      api
        .put(`/v1/settings/apps/update/${updatedRow.id}`, updatedRow, {
          authorization: accessToken,
        })
        .then(() => {
          console.log('Данные успешно изменены')
          setDialogOpen(false)
        })
        .catch((error) => {
          console.error('Ошибка изменения данных:', error)
        })
    })
  }

  const handleCreateApp = (appData) => {
    getToken().then((accessToken) => {
      api
        .post(`/v1/settings/apps/create`, appData, {
          authorization: accessToken,
        })
        .then(() => {
          console.log('успешное создание приложения')
          setCreateDialogOpen(false)
          refreshApps()
        })
        .catch((error) => {
          console.error('Ошибка создания приложения:', error)
        })
    })
  }

  return (
    <>
      {currentRow && (
        <EditDialog
          show={dialogOpen}
          onHide={() => setDialogOpen(false)}
          onSave={handleSave}
          title="Редактирование данных"
          fields={[
            { key: 'code', label: 'Код' },
            { key: 'ext', label: 'Расширение' },
            { key: 'template', label: 'Шаблон файла' },
            { key: 'versionTemplate', label: 'Шаблон версии' },
            { key: 'name', label: 'Описание' },
          ]}
          data={currentRow}
          setData={setCurrentRow}
        />
      )}
      <CreateDialog
        show={createDialogOpen}
        onHide={() => setCreateDialogOpen(false)}
        onSave={handleCreateApp}
        data={{ code: '', name: '', ext: '' }}
      />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          marginBottom: 2,
        }}
      >
        <Button
          variant={'contained'}
          color={'success'}
          disableElevation={true}
          size="large"
          onClick={() => setCreateDialogOpen(true)}
        >
          Новое приложение
        </Button>
      </Box>
      <ContentPaper>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="apps table">
            <TableHead>
              <TableRow>
                <TableCell>Код</TableCell>
                <TableCell>Расширение</TableCell>
                <TableCell>Шаблон файла</TableCell>
                <TableCell>Шаблон версии</TableCell>
                <TableCell>Описание</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {dataApps.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    sx={{
                      textAlign: 'center',
                      color: '#999',
                      height: 70,
                    }}
                  >
                    Список пуст
                  </TableCell>
                </TableRow>
              ) : null}
              {dataApps.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{row.code}</TableCell>
                  <TableCell>{row.ext}</TableCell>
                  <TableCell>{row.template}</TableCell>
                  <TableCell>{row.versionTemplate}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="edit"
                      onClick={() => handleEditClick(row)}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ContentPaper>
    </>
  )
}

export default AppsTable
