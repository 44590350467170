import React, { useContext, useState } from 'react'
import api from '../../shared/api'
import UsersContext from '../../app/Administration/UsersContext'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Box,
  Tooltip,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import LockResetIcon from '@mui/icons-material/LockReset'
import EditDialogUsers from '../EditDialog/EditDialogUsers'
import CreateDialogUser from '../CreateDialog/CreateDialogUser'
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog'
import ResetPasswordDialog from '../ResetPasswordDialog/ResetPasswordDialog'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import { useAuth } from '../../app/OAuth/Provider'
import ContentPaper from '../../shared/ui/Components/ContentPaper'

function UsersTable() {
  const { data, setData, refreshUsers } = useContext(UsersContext)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [currentRow, setCurrentRow] = useState(null)

  const [deleted, setDeleted] = useState({})
  const [deletingId, setDeletingId] = useState(null)
  const [showConfirm, setShowConfirm] = useState(false)
  const [showReset, setShowReset] = useState(false)
  const [resetRow, setResetRow] = useState(null)

  const [createDialogOpen, setCreateDialogOpen] = useState(false)

  const { getToken } = useAuth()

  const handleConfirmRemove = (row) => {
    setDeletingId(row.id)
    setShowConfirm(true)
  }

  const handleEditClick = (row) => {
    setCurrentRow(row)
    setDialogOpen(true)
  }

  const handleResetPasswordClick = (row) => {
    setResetRow(row)
    setShowReset(true)
  }

  const handleRemove = () => {
    getToken().then((accessToken) => {
      api
        .delete(`/v1/admins/users/remove/${deletingId}`, {
          authorization: accessToken,
        })
        .then((_) => {
          const newDeleted = {}
          for (const d in deleted) {
            newDeleted[d] = true
          }
          newDeleted[deletingId] = true
          setDeleted(newDeleted)
        })
        .catch((e) => {
          console.log(e)
        })
    })
  }

  const handleCreateUsers = async (userData) => {
    const token = await getToken()
    api
      .post(`/v1/admins/users/create`, userData, { authorization: token })
      .then(() => {
        console.log('Успешное создание пользователя')
        setCreateDialogOpen(false)
        refreshUsers()
      })
      .catch((error) => {
        console.error('Ошибка создания пользователя:', error)
      })
  }

  const handleSave = (updatedRow) => {
    const updatedData = data.map((row) =>
      row.id === updatedRow.id ? updatedRow : row
    )
    setData(updatedData)
    getToken().then((accessToken) => {
      api
        .put(`/v1/admins/users/update/${updatedRow.id}`, updatedRow, {
          authorization: accessToken,
        })
        .then(() => {
          console.log('Данные успешно изменены')
          refreshUsers()
        })
        .catch((error) => {
          console.error('Ошибка изменения данных:', error)
        })
    })
    setDialogOpen(false)
  }

  const handleResetPassword = async ({ id, newPassword }) => {
    const token = await getToken()
    api
      .put(
        `/v1/admins/users/reset/${id}`,
        { newPassword },
        { authorization: token }
      )
      .then(() => {
        console.log('Пароль успешно сброшен')
        setShowReset(false)
      })
      .catch((error) => {
        console.error('Ошибка сброса пароля:', error.response?.data || error)
      })
  }

  return (
    <>
      {resetRow && (
        <ResetPasswordDialog
          show={showReset}
          onHide={() => setShowReset(false)}
          onSave={({ newPassword }) =>
            handleResetPassword({ id: resetRow.id, newPassword })
          }
          data={resetRow}
        />
      )}
      <CreateDialogUser
        show={createDialogOpen}
        onHide={() => setCreateDialogOpen(false)}
        onSave={handleCreateUsers}
        data={{ email: '', password: '', repeat_password: '' }}
      />
      <ConfirmDialog
        show={showConfirm}
        message={'Удалить пользователя?'}
        onHide={() => setShowConfirm(false)}
        accept={handleRemove}
      />
      {currentRow && (
        <EditDialogUsers
          show={dialogOpen}
          onHide={() => setDialogOpen(false)}
          onSave={handleSave}
          data={currentRow}
          setData={setCurrentRow}
        />
      )}
      <Box
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          marginBottom: 2,
        }}
      >
        <Button
          variant={'contained'}
          color={'success'}
          disableElevation={true}
          size="large"
          onClick={() => setCreateDialogOpen(true)}
          startIcon={<PersonAddIcon />}
        >
          Добавить пользователя
        </Button>
      </Box>
      <ContentPaper>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Почта</TableCell>
                <TableCell>Роль</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={5}
                    sx={{
                      textAlign: 'center',
                      color: '#999',
                      height: 70,
                    }}
                  >
                    Список пуст
                  </TableCell>
                </TableRow>
              ) : null}

              {data.map((row) =>
                deleted[row.id] ? (
                  <TableRow
                    key={row.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell
                      colSpan={5}
                      sx={{
                        textAlign: 'center',
                        color: '#999',
                        height: 70,
                        background:
                          'linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0.03) 50%, rgba(0,0,0,0))',
                      }}
                    >
                      Пользователь удален
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow
                    key={row.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.role}</TableCell>
                    <TableCell align="right">
                      <Tooltip title="Изменить">
                        <IconButton
                          aria-label="edit"
                          onClick={() => handleEditClick(row)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Сбросить пароль">
                        <IconButton
                          aria-label="reset-password"
                          onClick={() => handleResetPasswordClick(row)}
                        >
                          <LockResetIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Удалить">
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleConfirmRemove(row)}
                          sx={{ color: '#b71c1c' }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </ContentPaper>
    </>
  )
}

export default UsersTable
