import React, { useEffect, useState } from 'react'
import { useAuth } from '../../app/OAuth/Provider'
import { Button, Stack } from '@mui/material'
import SystemMenu from '../../shared/ui/SystemMenu'
import ChartsIndicators from '../../widgets/Chart/ChartsIndicators'
import IndicatorsProvider from '../../app/Stats/IndicatorsProvider'
import StatsProvider from '../../app/Stats/StatsProvider'
import ContentPaper from '../../shared/ui/Components/ContentPaper'
import Chart from '../../widgets/Chart/Chart'

const getStartDate = (monthBefore) => {
  if (monthBefore === undefined) monthBefore = 1
  const d = new Date()
  d.setMonth(d.getMonth() - monthBefore)
  d.setHours(0, 0, 0, 0)
  return d.getTime() / 1000
}

function Indicators() {
  const { isAuthenticated, getToken, login } = useAuth()
  const [view, setView] = useState('general')

  useEffect(() => {
    getToken()
      .then((r) => {})
      .catch((r) => login())
  }, [])

  useEffect(() => {
    if (!isAuthenticated) login()
  }, [isAuthenticated])

  const showGeneral = () => setView('general')
  const showMap = () => setView('map')
  const showSonar = () => setView('sonar')
  const activeStyle = { backgroundColor: '#FFCC00', color: '#444' }

  const buttons = [
    {
      event: showGeneral,
      name: 'general',
      style: view === 'general' ? activeStyle : {},
    },
    {
      event: showMap,
      name: 'map',
      style: view === 'map' ? activeStyle : {},
    },
    {
      event: showSonar,
      name: 'sonar',
      style: view === 'sonar' ? activeStyle : {},
    },
  ]

  return (
    <SystemMenu>
      <h1>Показатели</h1>
      <IndicatorsProvider>
        <div
          style={{
            maxWidth: '215px',
            borderRadius: '15px',
            backgroundColor: '#fefefe',
            height: '42px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
          }}
        >
          {buttons.map((row, index) => (
            <Button
              key={index}
              onClick={row.event}
              style={row.style}
              sx={{
                padding: '7px',
                backgroundColor: 'white',
                borderRadius: '10px',
                color: '#ffb74d',
                textTransform: 'none',
              }}
              size="small"
            >
              {row.name}
            </Button>
          ))}
        </div>
        <Stack>
          <ChartsIndicators />
        </Stack>
      </IndicatorsProvider>

      <StatsProvider
        type="indicators"
        query={{
          startDate: getStartDate(2),
          endDate: getStartDate(0),
          segment: 'day',
          group: 'general',
          code: 'depth-points-count',
        }}
      >
        <ContentPaper>
          <h2 style={{ textAlign: 'left' }}>depth-points-count по дням</h2>
          <Chart segmentSize={24 * 3600 * 1000} />
        </ContentPaper>
      </StatsProvider>
    </SystemMenu>
  )
}

export default Indicators
