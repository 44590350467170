import React, { useContext, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Button,
  Stack,
} from '@mui/material'
import CopyAllIcon from '@mui/icons-material/CopyAll'
import EditIcon from '@mui/icons-material/Edit'
import TokenAccessContext from '../../app/Administration/TokenAccessContext'
import EditDialogTokenAccess from '../EditDialog/EditDialogTokenAccess'
import api from '../../shared/api'
import { useAuth } from '../../app/OAuth/Provider'
import ContentPaper from '../../shared/ui/Components/ContentPaper'

function OptionsTokenAccessTable() {
  const { getToken } = useAuth()

  const { data, setData, refreshTokens } = useContext(TokenAccessContext)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [currentRow, setCurrentRow] = useState(null)

  const createToken = () => {
    getToken().then((accessToken) => {
      api
        .post(`/v1/admins/tokens/create`, null, {
          authorization: accessToken,
        })
        .then(() => {
          console.log('Успешное создание токена')
          refreshTokens()
        })
        .catch((error) => {
          console.error('Ошибка создания токена:', error)
        })
    })
  }

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
  }

  const handleEditClick = (row) => {
    setCurrentRow(row)
    setDialogOpen(true)
  }

  const handleSave = (updatedRow) => {
    const updatedData = data.map((row) =>
      row.id === updatedRow.id ? updatedRow : row
    )
    setData(updatedData)
    getToken().then((accessToken) => {
      api
        .put(`/v1/admins/tokens/update/${updatedRow.id}`, updatedRow, {
          authorization: accessToken,
        })
        .then(() => {
          console.log('Данные успешно изменены')
          setDialogOpen(false)
        })
        .catch((error) => {
          console.error('Ошибка изменения данных:', error)
        })
    })
  }

  const colorThread = (part) => {
    return part === 'test' ? (
      <Box
        sx={{
          background: '#ffc400',
          borderRadius: '10px',
          padding: '3px 5px',
          width: '70px',
          height: '25px',
          textAlign: 'center',
        }}
      >
        {part}
      </Box>
    ) : (
      <Box
        sx={{
          background: '#b71c1c',
          borderRadius: '10px',
          color: '#fff',
          padding: '3px 5px',
          width: '70px',
          height: '25px',
          textAlign: 'center',
        }}
      >
        {part}
      </Box>
    )
  }
  return (
    <>
      {currentRow && (
        <EditDialogTokenAccess
          show={dialogOpen}
          onHide={() => setDialogOpen(false)}
          onSave={handleSave}
          data={currentRow}
          setData={setCurrentRow}
        />
      )}
      <Stack direction={'row'} justifyContent={'flex-end'}>
        <Button
          color={'success'}
          disableElevation={true}
          variant={'contained'}
          size="large"
          onClick={createToken}
        >
          Создать новый токен
        </Button>
      </Stack>
      <ContentPaper>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>id</TableCell>
                <TableCell>Ветка</TableCell>
                <TableCell>Создан</TableCell>
                <TableCell>Истекает</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={5}
                    sx={{
                      textAlign: 'center',
                      color: '#999',
                      height: 70,
                    }}
                  >
                    Список пуст
                  </TableCell>
                </TableRow>
              ) : null}

              {data.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell sx={{ color: '#8f8f8f' }}>
                    {row.id}
                    <IconButton
                      sx={{
                        marginLeft: '1.5rem',
                        marginTop: '-0.3rem',
                      }}
                      onClick={() => handleCopy(row.id)}
                    >
                      <CopyAllIcon aria-label="copy" />
                    </IconButton>
                  </TableCell>
                  <TableCell>{colorThread(row.thread)}</TableCell>
                  <TableCell>{row.create}</TableCell>
                  <TableCell>{row.expirationDate}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      aria-label="edit"
                      onClick={() => handleEditClick(row)}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ContentPaper>
    </>
  )
}

export default OptionsTokenAccessTable
